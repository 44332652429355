import React from "react"
import Banner from "../components/banner"
import Footer from "../components/footer"

export default () => (
    <div id="main">
        <Banner />
        <div id="slogan">
            <img src={'logo.jpg'} alt="" />
            <div id="slogan-msg">
                Doing great things soon!
            </div>
        </div>
        <div id="separator">
            <hr/>
        </div>
        <Footer />
        <div id="separator-bottom">
            <hr class="footer-bottom" />
        </div>
    </div>
)
